import React from 'react';
import PropTypes from 'prop-types';

import Theme from 'components/atoms/objects/Theme/Theme';
import Layer from 'components/atoms/objects/Layer/Layer';
import Retain from 'components/atoms/objects/Retain/Retain';
import Text from 'components/molecules/Text/Text';

const TextBlock = (props) => {
  const {
    data,
    themeColor,
  } = props;

  return (
    <Theme color={themeColor}>
      <Layer size="large" id={data.id}>
        <Retain size="desk">
          <Text {...data} />
        </Retain>
      </Layer>
    </Theme>
  );
};

TextBlock.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  themeColor: PropTypes.string,
};

TextBlock.defaultProps = {
  data: null,
  themeColor: '',
};

export default TextBlock;
