import React from 'react';
import SEO from 'components/seo';

// Component
import TextBlock from 'components/organisms/Blocks/TextBlock/TextBlock';
import ImageOverview from 'components/organisms/Blocks/ImageOverview/ImageOverview';
import Hero from 'components/molecules/Hero/Hero';

// Data
import ProjectenData from 'data/ProjectenData/ProjectenData.json';

const ProjectPage = () => (
  <>
    <SEO title="Projecten" />
    <TextBlock
      data={ProjectenData.projectInfo}
    />
    <ImageOverview
      layerModifier="flatTop"
      data={ProjectenData.projectenOverview}
    />
    <Hero
      modifier="inPage"
      {...ProjectenData.inPageHero}
    />
  </>
);
export default ProjectPage;
