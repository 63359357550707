import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import Img from 'gatsby-image';
import Icon from 'components/atoms/Icons/Icon';

import './ListImages.scss';

const ListImages = (props) => {
  const {
    images,
    modifier,
    className,
    inline,
    imagesPerRow,
    imageLimit,
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

  const closeModal = () => setModalIsOpen(false);
  const openModal = (imageIndex) => {
    setModalCurrentIndex(imageIndex);
    setModalIsOpen(true);
  };

  const prevModalImage = () => {
    setModalCurrentIndex((modalCurrentIndex + images.length - 1) % images.length);
  };

  const nextModalImage = () => {
    setModalCurrentIndex((modalCurrentIndex + 1) % images.length);
  };

  return (
    <>
      <div className="c-list-image">
        <ul className={classNames('o-list-clean  o-layout  o-layout--gutter-small', {
          'o-layout--align-center': modifier === 'center',
        }, className)}
        >
          {images.slice(0, imageLimit).map((image, i) => (
            <li
              key={image.id}
              className={classNames(`o-layout__cell  u-fraction--6/12  u-fraction--${imagesPerRow}/12@from-lap`, {
                'o-layout__cell--fit': inline === true,
              })}
            >
              <a
                className="c-list-image__item"
                href={image.originalImg}
                onClick={(e) => {
                  e.preventDefault();
                  openModal(i);
                }}
              >
                <Img
                  fluid={image}
                />
                <span className="c-list-image__item__wrap-icon">
                  <Icon
                    icon="icon-zoom"
                    size="epsilon"
                  />
                </span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      {modalIsOpen && (
        <Lightbox
          mainSrc={images[modalCurrentIndex].originalImg}
          nextSrc={images[(modalCurrentIndex + 1) % images.length].originalImg}
          prevSrc={images[(modalCurrentIndex + images.length - 1) % images.length].originalImg}
          onCloseRequest={closeModal}
          onMovePrevRequest={prevModalImage}
          onMoveNextRequest={nextModalImage}
          imageCaption={images[modalCurrentIndex].title}
          enableZoom={false}
          clickOutsideToClose
          imageLoadErrorMessage=""
        />
      )}
    </>
  );
};

ListImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  modifier: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool,
  imagesPerRow: PropTypes.number,
  imageLimit: PropTypes.number,
};

ListImages.defaultProps = {
  images: null,
  modifier: null,
  className: null,
  inline: true,
  imagesPerRow: 4,
  imageLimit: 99,
};

export default ListImages;
